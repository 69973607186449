.waypoint-animate,.way-point{
	position: relative;	
	transition: all 0.6s ease-in-out;
	&.animate-fade {
		opacity: 0;		
		&.shown{
			opacity: 1;			
		}	
	}
	&.animate-top {
		opacity: 0;		
		transform:translateY(170px);
        &.animate-top-70{
            transform:translateY(70px);
            &.shown{
                transform:translateY(0) ;
            }	
        }
		&.shown{
			opacity: 1;
			transform:translateY(0) ;
		}	
	}
	&.animate-bottom {
		opacity: 0;
		transform:translateY(-170px) ;
        &.animate-bottom-70{
            transform:translateY(-70px);
            &.shown{                
                transform:translateY(0) ;
            }	
        }
		&.shown{
			opacity: 1;
			transform:translateY(0) ;
		}
		
	}
	&.animate-left {
		opacity: 0;		
		transform:translateX(170px) ;
        &.animate-left-70{
            transform:translateX(70px) ;
            &.shown{                
                transform:translateX(0) ;
            }	
        }
		&.shown{
			opacity: 1;
			transform:translateX(0) ;
		}	
	}
	&.animate-right {
		opacity: 0;
		transform:translateX(-170px) ;
        &.animate-right-70{
            transform:translateX(-70px) ;
            &.shown{                
                transform:translateX(0) ;
            }	
        }
		&.shown{
			opacity: 1;
			transform:translateX(0) ;
		}
	}

	&.delay-1{
		transition-delay:0.2s!important;
		-webkit-transition-delay: 0.2s!important;
	}
	&.delay-2{
		transition-delay:0.3!important;
		-webkit-transition-delay: 0.3s!important;
	}
	&.delay-3{
		transition-delay:0.4s!important;
		-webkit-transition-delay: 0.4s!important;
	}
	&.delay-4{
		transition-delay:0.5s!important;
		-webkit-transition-delay: 0.5s!important;
	}
	&.delay-5{
		transition-delay:0.6s!important;
		-webkit-transition-delay: 0.6s!important;
	}
	&.delay-6{
		transition-delay:0.7s!important;
		-webkit-transition-delay: 0.7s!important;
	}
	&.delay-7{
		transition-delay:0.8s!important;
		-webkit-transition-delay: 0.8s!important;
	}
	&.delay-8{
		transition-delay:0.9s!important;
		-webkit-transition-delay: 0.9s!important;
	}
	&.delay-9{
		transition-delay:1s!important;
		-webkit-transition-delay: 1s!important;
	}
	&.delay-10{
		transition-delay:1.1s!important;
		-webkit-transition-delay: 1.1s!important;
	}
	&.delay-11{
		transition-delay:1.2s!important;
		-webkit-transition-delay: 1.2s!important;
	}
	&.delay-12{
		transition-delay:1.3s!important;
		-webkit-transition-delay: 1.3s!important;
	}
    
}