.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px; 
    padding-right: 10px;
    padding-left: 10px;
}
.col-xs-15 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
@media (min-width: 768px) {
    .col-sm-15 {
         -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}  
.col-pusher{
    padding-bottom: 30px;    
}
.col-12 {
    &.col-pusher{
        @media (max-width: 767px) {
            padding-bottom: 15px;    
        }
    }
}